import { ChainId, graphql_fetch } from "common";
import { fetchFromIPFS } from "./utils";
const validRounds = ["0x35c9d05558da3a3f3cddbf34a8e364e59b857004", "0x984e29dcb4286c2d9cbaa2c238afdd8a191eefbc", "0x4195cd3cd76cc13faeb94fdad66911b4e0996f38"];
const invalidRounds = ["0xde272b1a1efaefab2fd168c02b8cf0e3b10680ef"];
async function fetchRoundsByTimestamp(query, chainId, debugModeEnabled) {
  try {
    const chainIdEnumValue = ChainId[chainId];
    const currentTimestamp = Math.floor(Date.now() / 1000).toString();
    const res = await graphql_fetch(query, chainIdEnumValue, {
      currentTimestamp
    });
    if (!res.data || !res.data.rounds) {
      return [];
    }
    const rounds = res.data.rounds;
    const filteredRounds = [];
    for (const round of rounds) {
      var _round$roundMetadata;
      const roundMetadata = await fetchFromIPFS(round.roundMetaPtr.pointer);
      round.roundMetadata = roundMetadata;
      round.chainId = chainId;

      // check if roundType is public & if so, add to filteredRounds
      if (((_round$roundMetadata = round.roundMetadata) === null || _round$roundMetadata === void 0 ? void 0 : _round$roundMetadata.roundType) === "public") {
        filteredRounds.push(round);
      }

      // check if round.id is in validRounds & if so, add to filteredRounds
      if (validRounds.includes(round.id)) {
        filteredRounds.push(round);
      }

      // check if round.id is in invalidRounds & if so, remove from filteredRounds
      if (invalidRounds.includes(round.id)) {
        const index = filteredRounds.findIndex(r => r.id === round.id);
        if (index > -1) {
          filteredRounds.splice(index, 1);
        }
      }
    }
    return debugModeEnabled ? rounds : filteredRounds;
  } catch (error) {
    console.log("error: fetchRoundsByTimestamp", error);
    return [];
  }
}
function getActiveChainIds() {
  const activeChainIds = [];
  const isProduction = process.env.REACT_APP_ENV === "production";
  for (const chainId of Object.values(ChainId)) {
    if (!isNaN(+chainId)) {
      continue;
    }
    if (isProduction && [ChainId.GOERLI_CHAIN_ID, ChainId.FANTOM_MAINNET_CHAIN_ID, ChainId.FANTOM_TESTNET_CHAIN_ID].includes(ChainId[chainId])) {
      continue;
    }
    activeChainIds.push(chainId.toString());
  }
  return activeChainIds;
}
export async function getRoundsInApplicationPhase(debugModeEnabled) {
  const chainIds = getActiveChainIds();
  const query = `
      query GetRoundsInApplicationPhase($currentTimestamp: String) {
        rounds(where: {
          applicationsStartTime_lt: $currentTimestamp
          applicationsEndTime_gt: $currentTimestamp
        }) {
          id
          roundMetaPtr {
            protocol
            pointer
          }
          applicationsStartTime
          applicationsEndTime
          roundStartTime
          roundEndTime
          matchAmount
          token

          projects(where:{
            status: 1
          }) {
            id
          }
        }
      }
    `;
  const rounds = await Promise.all(chainIds.map(chainId => fetchRoundsByTimestamp(query, chainId, debugModeEnabled)));
  return rounds.flat();
}
export async function getActiveRounds(debugModeEnabled) {
  const chainIds = getActiveChainIds();
  const query = `
      query GetActiveRounds($currentTimestamp: String) {
        rounds(where: {
          roundStartTime_lt: $currentTimestamp
          roundEndTime_gt: $currentTimestamp
        }) {
          id
          roundMetaPtr {
            protocol
            pointer
          }
          applicationsStartTime
          applicationsEndTime
          roundStartTime
          roundEndTime
          matchAmount
          token

          projects(where:{
            status: 1
          }) {
            id
          }
        }
      }
    `;
  const rounds = await Promise.all(chainIds.map(chainId => fetchRoundsByTimestamp(query, chainId, debugModeEnabled)));
  return rounds.flat();
}