var _process$env$REACT_AP;
import "@rainbow-me/rainbowkit/styles.css";
import { connectorsForWallets, getDefaultWallets } from "@rainbow-me/rainbowkit";
import { coinbaseWallet, injectedWallet, walletConnectWallet, metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { infuraProvider } from "wagmi/providers/infura";
import { allChains } from "./chainConfig";
export const {
  chains,
  publicClient,
  webSocketPublicClient
} = configureChains(allChains, [infuraProvider({
  apiKey: process.env.REACT_APP_INFURA_ID
}), publicProvider()]);

/* TODO: remove hardcoded value once we have environment variables validation */
const projectId = (_process$env$REACT_AP = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID) !== null && _process$env$REACT_AP !== void 0 ? _process$env$REACT_AP : "2685061cae0bcaf2b244446153eda9e1";
const {
  wallets
} = getDefaultWallets({
  appName: "Grant Explorer",
  projectId,
  chains
});

// Custom wallet connectors: more can be added by going here:
// https://www.rainbowkit.com/docs/custom-wallet-list
const connectors = connectorsForWallets([{
  ...wallets,
  groupName: "Recommended",
  wallets: [injectedWallet({
    chains
  }), walletConnectWallet({
    chains,
    projectId
  }), coinbaseWallet({
    appName: "Gitcoin Explorer",
    chains
  }), metaMaskWallet({
    chains,
    projectId
  })]
}]);
export const config = createConfig({
  autoConnect: true,
  connectors: connectors,
  publicClient,
  webSocketPublicClient
});