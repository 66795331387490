export default [{
  inputs: [],
  name: "AmountsNotEqualRoundsLength",
  type: "error"
}, {
  inputs: [],
  name: "ExcessAmountSent",
  type: "error"
}, {
  inputs: [],
  name: "VotesNotEqualRoundsLength",
  type: "error"
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: "uint8",
    name: "version",
    type: "uint8"
  }],
  name: "Initialized",
  type: "event"
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: "address",
    name: "previousOwner",
    type: "address"
  }, {
    indexed: true,
    internalType: "address",
    name: "newOwner",
    type: "address"
  }],
  name: "OwnershipTransferred",
  type: "event"
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: "address",
    name: "account",
    type: "address"
  }],
  name: "Paused",
  type: "event"
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: "address",
    name: "account",
    type: "address"
  }],
  name: "Unpaused",
  type: "event"
}, {
  inputs: [],
  name: "initialize",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function"
}, {
  inputs: [],
  name: "owner",
  outputs: [{
    internalType: "address",
    name: "",
    type: "address"
  }],
  stateMutability: "view",
  type: "function"
}, {
  inputs: [],
  name: "pause",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function"
}, {
  inputs: [],
  name: "paused",
  outputs: [{
    internalType: "bool",
    name: "",
    type: "bool"
  }],
  stateMutability: "view",
  type: "function"
}, {
  inputs: [],
  name: "renounceOwnership",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function"
}, {
  inputs: [{
    internalType: "address",
    name: "newOwner",
    type: "address"
  }],
  name: "transferOwnership",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function"
}, {
  inputs: [],
  name: "unpause",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function"
}, {
  inputs: [{
    internalType: "bytes[][]",
    name: "votes",
    type: "bytes[][]"
  }, {
    internalType: "address[]",
    name: "rounds",
    type: "address[]"
  }, {
    internalType: "uint256[]",
    name: "amounts",
    type: "uint256[]"
  }],
  name: "vote",
  outputs: [],
  stateMutability: "payable",
  type: "function"
}, {
  inputs: [{
    internalType: "bytes[][]",
    name: "votes",
    type: "bytes[][]"
  }, {
    internalType: "address[]",
    name: "rounds",
    type: "address[]"
  }, {
    internalType: "uint256[]",
    name: "amounts",
    type: "uint256[]"
  }, {
    internalType: "uint256",
    name: "totalAmount",
    type: "uint256"
  }, {
    internalType: "address",
    name: "token",
    type: "address"
  }, {
    internalType: "uint256",
    name: "deadline",
    type: "uint256"
  }, {
    internalType: "uint256",
    name: "nonce",
    type: "uint256"
  }, {
    internalType: "uint8",
    name: "v",
    type: "uint8"
  }, {
    internalType: "bytes32",
    name: "r",
    type: "bytes32"
  }, {
    internalType: "bytes32",
    name: "s",
    type: "bytes32"
  }],
  name: "voteDAIPermit",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function"
}, {
  inputs: [{
    internalType: "bytes[][]",
    name: "votes",
    type: "bytes[][]"
  }, {
    internalType: "address[]",
    name: "rounds",
    type: "address[]"
  }, {
    internalType: "uint256[]",
    name: "amounts",
    type: "uint256[]"
  }, {
    internalType: "uint256",
    name: "totalAmount",
    type: "uint256"
  }, {
    internalType: "address",
    name: "token",
    type: "address"
  }, {
    internalType: "uint256",
    name: "deadline",
    type: "uint256"
  }, {
    internalType: "uint8",
    name: "v",
    type: "uint8"
  }, {
    internalType: "bytes32",
    name: "r",
    type: "bytes32"
  }, {
    internalType: "bytes32",
    name: "s",
    type: "bytes32"
  }],
  name: "voteERC20Permit",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function"
}];