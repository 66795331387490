import { fetchFromIPFS, graphql_fetch } from "./utils";
import { ApplicationStatus } from "./types";
import { Client as AlloIndexerClient } from "allo-indexer-client";
import { useEffect, useState } from "react";
import { getAddress } from "viem";
import { fetchHypercertMetadata } from "common/src/hypercert";

/**
 * Shape of subgraph response
 */

/**
 * Shape of subgraph response of Round
 */

/**
 * Shape of IPFS content of Round RoundMetaPtr
 */

export async function getRoundById(roundId,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
chainId) {
  try {
    // get the subgraph for round by $roundId
    const res = await graphql_fetch(`
        query GetRoundById($roundId: String) {
          rounds(where: {
            id: $roundId
          }) {
            id
            program {
              id
            }
            roundMetaPtr {
              protocol
              pointer
            }
            applicationMetaPtr {
              protocol
              pointer
            }
            applicationsStartTime
            applicationsEndTime
            roundStartTime
            roundEndTime
            token
            votingStrategy
            projectsMetaPtr {
              pointer
            }
            projects(
              first: 1000
              where:{
                status: 1
              }
            ) {
              id
              project
              status
              applicationIndex
              metaPtr {
                protocol
                pointer
              }
            }
          }
        }
      `, chainId, {
      roundId
    });
    const round = res.data.rounds[0];
    const roundMetadata = await fetchFromIPFS(round.roundMetaPtr.pointer);
    round.projects = round.projects.map(project => {
      return {
        ...project,
        status: convertStatus(project.status)
      };
    });
    const approvedProjectsWithMetadata = await loadApprovedProjectsMetadata(round, chainId);
    return {
      id: roundId,
      roundMetadata,
      applicationsStartTime: new Date(parseInt(round.applicationsStartTime) * 1000),
      applicationsEndTime: new Date(parseInt(round.applicationsEndTime) * 1000),
      roundStartTime: new Date(parseInt(round.roundStartTime) * 1000),
      roundEndTime: new Date(parseInt(round.roundEndTime) * 1000),
      token: round.token,
      votingStrategy: round.votingStrategy,
      ownedBy: round.program.id,
      approvedProjects: approvedProjectsWithMetadata
    };
  } catch (error) {
    console.error("getRoundById", error);
    throw Error("Unable to fetch round");
  }
}
export function convertStatus(status) {
  switch (status) {
    case 0:
      return "PENDING";
    case 1:
      return "APPROVED";
    case 2:
      return "REJECTED";
    case 3:
      return "CANCELLED";
    default:
      return "PENDING";
  }
}
async function loadApprovedProjectsMetadata(round,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
chainId) {
  if (round.projects.length === 0) {
    return [];
  }
  const approvedProjects = round.projects;
  const roundMetadata = await fetchFromIPFS(round.roundMetaPtr.pointer);
  const fetchApprovedProjectMetadata = approvedProjects.map(project => fetchMetadataAndMapProject(project, !!(roundMetadata !== null && roundMetadata !== void 0 && roundMetadata.hypercertRequired), chainId));
  return Promise.all(fetchApprovedProjectMetadata);
}
async function fetchMetadataAndMapProject(project, hypercertRequired,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
chainId) {
  const applicationData = await fetchFromIPFS(project.metaPtr.pointer);
  // NB: applicationData can be in two formats:
  // old format: { round, project, ... }
  // new format: { signature: "...", application: { round, project, ... } }
  const application = applicationData.application || applicationData;
  const projectMetadataFromApplication = application.project;
  let hypercerts = [];
  if (hypercertRequired) {
    const metadata = await fetchFromIPFS(projectMetadataFromApplication.metaPtr.pointer);
    if (metadata.hypercertIds) {
      hypercerts = await Promise.all(metadata.hypercertIds.map(hypercertId => fetchHypercertMetadata(hypercertId, chainId).then(hypercert => fetchFromIPFS(hypercert.uri.replace("ipfs://", ""))).then(res => ({
        ...res,
        id: hypercertId
      }))));
    } else {
      console.log("Hypercert ID not found for project", project);
    }
  }
  const projectRegistryId = `0x${projectMetadataFromApplication.id}`;
  const projectOwners = await getProjectOwners(chainId, projectRegistryId);
  return {
    grantApplicationId: project.id,
    grantApplicationFormAnswers: application.answers,
    projectRegistryId: project.project,
    recipient: application.recipient,
    projectMetadata: {
      ...projectMetadataFromApplication,
      hypercerts,
      owners: projectOwners.map(address => ({
        address
      }))
    },
    status: ApplicationStatus.APPROVED,
    applicationIndex: project.applicationIndex
  };
}
export async function getProjectOwners(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
chainId, projectRegistryId) {
  try {
    var _res$data, _res$data$projects$;
    // get the subgraph for project owners by $projectRegistryId
    const res = await graphql_fetch(`
        query GetProjectOwners($projectRegistryId: String) {
          projects(where: {
            id: $projectRegistryId
          }) {
            id
            accounts {
              account {
                address
              }
            }
          }
        }
      `, chainId, {
      projectRegistryId
    }, true);
    return ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : (_res$data$projects$ = _res$data.projects[0]) === null || _res$data$projects$ === void 0 ? void 0 : _res$data$projects$.accounts.map(account => getAddress(account.account.address))) || [];
  } catch (error) {
    console.log("getProjectOwners", error);
    throw Error("Unable to fetch project owners");
  }
}
export const useContributionHistory = (chainIds, rawAddress) => {
  const [state, setState] = useState({
    type: "loading"
  });
  useEffect(() => {
    if (!process.env.REACT_APP_ALLO_API_URL) {
      throw new Error("REACT_APP_ALLO_API_URL is not set");
    }
    const fetchContributions = async () => {
      const fetchPromises = chainIds.map(chainId => {
        if (!process.env.REACT_APP_ALLO_API_URL) {
          throw new Error("REACT_APP_ALLO_API_URL is not set");
        }
        const client = new AlloIndexerClient(fetch.bind(window), process.env.REACT_APP_ALLO_API_URL, chainId);
        let address = "";
        try {
          // ensure the address is a valid address
          address = getAddress(rawAddress.toLowerCase());
        } catch (e) {
          return Promise.resolve({
            chainId,
            error: "Invalid address",
            data: []
          });
        }
        return client.getContributionsByAddress(address).then(data => {
          return {
            chainId,
            error: undefined,
            data
          };
        }).catch(error => {
          console.log(`Error fetching contribution history for chain ${chainId}:`, error);
          return {
            chainId,
            error: error.toString(),
            data: []
          };
        });
      });
      const fetchResults = await Promise.all(fetchPromises);
      if (fetchResults.every(result => result.error)) {
        setState({
          type: "error",
          error: "Error fetching contribution history for all chains"
        });
      } else {
        setState({
          type: "loaded",
          data: fetchResults
        });
      }
    };
    fetchContributions();
  }, [chainIds, rawAddress]);
  return state;
};