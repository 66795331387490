import { ChainId } from "common";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { payoutTokensMap } from "./features/api/utils";
const ethOnlyPayoutTokens = Object.fromEntries(Object.entries(payoutTokensMap).map(_ref => {
  var _value$find;
  let [key, value] = _ref;
  return [Number(key), (_value$find = value.find(token => token.defaultForVoting && token.canVote)) !== null && _value$find !== void 0 ? _value$find : value[0]];
}));
export const useCartStorage = create()(persist((set, get) => ({
  projects: [],
  add: project => {
    if (get().projects.includes(project)) {
      return;
    }
    set({
      projects: [...get().projects, project]
    });
  },
  remove: grantApplicationId => {
    set({
      projects: get().projects.filter(proj => proj.grantApplicationId !== grantApplicationId)
    });
  },
  clear: () => {
    set({
      projects: []
    });
  },
  updateDonationsForChain: (chainId, amount) => {
    const newState = get().projects.map(project => ({
      ...project,
      amount: project.chainId === chainId ? amount : project.amount
    }));
    set({
      projects: newState
    });
  },
  updateDonationAmount: (grantApplicationId, amount) => {
    if (amount.includes("-")) {
      return;
    }
    const projectIndex = get().projects.findIndex(donation => donation.grantApplicationId === grantApplicationId);
    if (projectIndex !== -1) {
      const newState = [...get().projects];
      newState[projectIndex].amount = amount;
      set({
        projects: newState
      });
    }
  },
  chainToPayoutToken: ethOnlyPayoutTokens,
  setPayoutTokenForChain: (chainId, payoutToken) => {
    if (!Object.values(ChainId).includes(chainId)) {
      console.warn("Tried setting payoutToken", payoutToken, "for chain", chainId, ", but chain", chainId, " doesn't exist");
      return;
    }
    set({
      chainToPayoutToken: {
        ...get().chainToPayoutToken,
        [chainId]: payoutToken
      }
    });
  }
}), {
  /*This is the localStorage key. Change this whenever the shape of the stores objects changes. append a v1, v2. etc. */
  name: "cart-storage",
  version: 2
}));