import { MRC_CONTRACTS } from "./contracts";
import { encodeAbiParameters, getAddress, hexToNumber, parseAbiParameters, parseUnits, slice, zeroAddress } from "viem";
import mrcAbi from "./abi/multiRoundCheckout";
import { getContract, getPublicClient } from "@wagmi/core";
import { allChains } from "../../app/chainConfig";
/** Given a payout token, selects the correct permit type.
 * - DAI is the old permit type without `value` and with the `allowed` prop
 * - eip2612 is the standard permit interface, as specified in https://eips.ethereum.org/EIPS/eip-2612
 *
 * Old DAI permit type is only implemented on Ethereum and Polygon PoS. Check /docs/DAI.md for more info.
 * */
export const getPermitType = token => {
  if (/DAI/i.test(token.name) && token.chainId === 1 /* || token.chainId === 137 Polygon not yet supported, but soon */) {
    return "dai";
  } else {
    return "eip2612";
  }
};
export const voteUsingMRCContract = async (walletClient, chainId, token, groupedVotes, groupedAmounts, nativeTokenAmount, permit) => {
  const mrcImplementation = getContract({
    address: MRC_CONTRACTS[await walletClient.getChainId()],
    abi: mrcAbi,
    walletClient,
    chainId
  });
  let tx;

  /* decide which function to use based on whether token is native, permit-compatible or DAI */
  if (token.address === zeroAddress) {
    tx = await mrcImplementation.write.vote([Object.values(groupedVotes), Object.keys(groupedVotes), Object.values(groupedAmounts)], {
      value: nativeTokenAmount,
      chain: allChains.find(chain => chain.id === chainId)
    });
  } else if (permit) {
    if (getPermitType(token) === "dai") {
      var _permit$deadline;
      tx = await mrcImplementation.write.voteDAIPermit([Object.values(groupedVotes), Object.keys(groupedVotes), Object.values(groupedAmounts), Object.values(groupedAmounts).reduce((acc, b) => acc + b), token.address, BigInt((_permit$deadline = permit.deadline) !== null && _permit$deadline !== void 0 ? _permit$deadline : Number.MAX_SAFE_INTEGER), permit.nonce, permit.sig.v, permit.sig.r, permit.sig.s]);
    } else {
      var _permit$deadline2;
      tx = await mrcImplementation.write.voteERC20Permit([Object.values(groupedVotes), Object.keys(groupedVotes), Object.values(groupedAmounts), Object.values(groupedAmounts).reduce((acc, b) => acc + b), token.address, BigInt((_permit$deadline2 = permit.deadline) !== null && _permit$deadline2 !== void 0 ? _permit$deadline2 : Number.MAX_SAFE_INTEGER), permit.sig.v, permit.sig.r, permit.sig.s]);
    }
  } else {
    /* Tried voting using erc-20 but no permit signature provided */
    throw new Error("Tried voting using erc-20 but no permit signature provided");
  }

  /* Check */
  const pc = getPublicClient({
    chainId
  });
  return pc.waitForTransactionReceipt({
    hash: tx,
    timeout: 20000000
  });
};
/* Signs a permit for EIP-2612-compatible ERC-20 tokens */
export const signPermit2612 = async _ref => {
  let {
    walletClient,
    contractAddress,
    erc20Name,
    ownerAddress,
    spenderAddress,
    value,
    deadline,
    nonce,
    chainId,
    permitVersion
  } = _ref;
  const types = {
    Permit: [{
      name: "owner",
      type: "address"
    }, {
      name: "spender",
      type: "address"
    }, {
      name: "value",
      type: "uint256"
    }, {
      name: "nonce",
      type: "uint256"
    }, {
      name: "deadline",
      type: "uint256"
    }]
  };
  const domainData = {
    name: erc20Name,
    version: permitVersion !== null && permitVersion !== void 0 ? permitVersion : "1",
    chainId: chainId,
    verifyingContract: contractAddress
  };
  const message = {
    owner: ownerAddress,
    spender: spenderAddress,
    value,
    nonce,
    deadline
  };
  const signature = await walletClient.signTypedData({
    account: ownerAddress,
    message,
    domain: domainData,
    primaryType: "Permit",
    types
  });
  const [r, s, v] = [slice(signature, 0, 32), slice(signature, 32, 64), slice(signature, 64, 65)];
  return {
    r,
    s,
    v: hexToNumber(v)
  };
};
export const signPermitDai = async _ref2 => {
  let {
    walletClient,
    contractAddress,
    erc20Name,
    ownerAddress,
    spenderAddress,
    deadline,
    nonce,
    chainId,
    permitVersion
  } = _ref2;
  const types = {
    Permit: [{
      name: "holder",
      type: "address"
    }, {
      name: "spender",
      type: "address"
    }, {
      name: "nonce",
      type: "uint256"
    }, {
      name: "expiry",
      type: "uint256"
    }, {
      name: "allowed",
      type: "bool"
    }]
  };
  const domainData = {
    name: erc20Name,
    version: permitVersion !== null && permitVersion !== void 0 ? permitVersion : "1",
    chainId: chainId,
    verifyingContract: contractAddress
  };
  const message = {
    holder: ownerAddress,
    spender: spenderAddress,
    nonce,
    expiry: deadline,
    allowed: true
  };
  const signature = await walletClient.signTypedData({
    account: ownerAddress,
    domain: domainData,
    primaryType: "Permit",
    types,
    message
  });
  const [r, s, v] = [slice(signature, 0, 32), slice(signature, 32, 64), slice(signature, 64, 65)];
  return {
    r,
    s,
    v: hexToNumber(v)
  };
};
export function encodeQFVotes(donationToken, donations) {
  return donations.map(donation => {
    const vote = [getAddress(donationToken.address), parseUnits(donation.amount, donationToken.decimal), getAddress(donation.recipient), donation.projectRegistryId, BigInt(donation.applicationIndex)];
    return encodeAbiParameters(parseAbiParameters(["address,uint256,address,bytes32,uint256"]), vote);
  });
}